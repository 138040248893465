<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Preloader', // eslint-disable-line
})
</script>

<template>
  <div id="app-loading" />
</template>

<style lang="scss">
#app-loading {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

#app {
  display: inline;
}
</style>
