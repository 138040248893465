/**
 * First file imported by the app
 *
 * This should NOT contain ANY dependency
 * except the ones necessary to show the preloader
 * so be careful
 */
import { createApp } from 'vue'

import Preloader from './Preloader.vue'

const preloaderApp = createApp(Preloader)

// @ts-ignore
preloaderApp.mount('#app-loading-container')

import('./main.js')
  .then(main => main)
  .catch(e => console.error(e))
